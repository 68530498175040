<template>
  <div class="about">
    <div class="about_1">
      <!-- 关于我们第一页 -->
      <div class="about_1_1">
        <div class="about_1_2">
          <p><b>公司简介</b></p>
        </div>
        <div class="about_1_3">
          <span >
            &emsp;&emsp;广州思迈信息科技有限公司是提供医疗信息系统精细化管理的专业供应商，以用思想服务医疗为理念，将科学的管理方法融入医疗信息化，让管理流程更合理，让从业人员更专业！<br/>
            &emsp;&emsp;公司核心团队从业医疗信息行业二十年，参与二级以上医院全院信息化建设100家、熟悉医院信息系统相关政策及行业标准、熟悉医院信息系统建设内容、熟悉医院信息系统项目建设流程。<br/>
            &emsp;&emsp;公司将以项目管理为核心，深度参与医疗信息化建设规划与过程，帮助医疗信息化管理规范化、标准化，旨在打造医疗信息化保障管理系统的领军企业。<br/>
          </span>
        </div>
      </div>
      <!-- 第二页 -->
      <div class="about_2">
        <div class="about_2_1">
          <p><b>公司理念</b></p>
        </div>
        <div class="about_2_2">
          <p> 用思想服务医疗 </p>
        </div>
        <div class="about_2_1">
          <p><b>公司使命</b></p>
        </div>
        <div class="about_2_2">
          <p> 将科学的管理方法融入医疗信息化！ </p>
        </div>
        <div class="about_2_1">
          <p><b>公司愿景</b></p>
        </div>
        <div class="about_2_2">
          <p> 医疗信息管理流程更合理，医疗信息从业人员更专业！ </p>
        </div>
        <div class="about_2_1">
          <p><b>公司价值观</b></p>
        </div>
        <div class="about_2_2">
          <p> 诚信！诚信！用心！ </p>
        </div>
        <div class="about_2_1">
          <p><b>联系我们</b></p>
        </div>
        <div class="about_2_2">
          <p>
            电话：020-61796811<br />
            邮箱：gzsmile2020@163.com<br />
            手机：13902386046、18138233853<br />
          </p>
        </div>
      </div>
      <div style="margin-top:24px;margin-bottom:24px">
          <el-image
              style="width: 100%"
              :src="require('@/assets/print/print9.png')"
            ></el-image>
      </div>
      <!-- 第三页 -->
      <div class="about_3">
        <div class="about_3_1">
          <el-image :src="require('../assets/address.png')"
          style="width:100%;"
          > </el-image>
        </div>
        <div class="about_3_2">
          <span>
            公司地址：广州市白云区永泰丛云路利都商务中心C座512室<br />
            地铁路线：乘坐地铁3号线永泰地铁站下车，A、B口出，延同泰路走至永泰路口，再顺着丛云路向里走一百米。<br />
            公交路线：792A路，广424路，76路，126路，743路，792A路，798路，803路<br />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    
};
</script>

<style scoped>
.about{
    display: flex;
    justify-content: center;
}
.about .about_1{
    width: 1200px;
   
}
.about .about_2{
    width: 1200px;
   
}
.about .about_3{
    width: 1200px;
}


/* 文字颜色，字体样式，大小 */
/* 公司简介 */
.about .about_1 .about_1_1 p{
     text-align: center;
    font-family: STXinwei;
    font-size: 36px;
    color: #666666;
}

.about .about_1 .about_1_2{
    margin-top: 24px;
    margin-bottom: 24px;
}

.about .about_1 .about_1_3{
    margin: auto;
    width: 800px;
}

.about .about_1 .about_1_3 span{
    font-size: 20px;
    font-family: Microsoft YaHei;
    text-align: left;
    line-height: 40px;
}

/* 公司理念 */
.about .about_2 .about_2_1{
        margin-top: 30px;
    margin-bottom: 10px;
}
.about .about_2 .about_2_1 p{
         
    font-family: STXinwei;
    text-align: center;
    font-size: 36px;
    color: #666666;
}

.about .about_2 .about_2_2 p{
       margin: auto;    
    font-family: Microsoft YaHei;
    text-align: center;
    font-size: 28px;
}

.about .about_3 .about_3_1{
    
    width: 1200px;
}
.about .about_3 .about_3_2{
    font-family: Microsoft YaHei;
    font-size: 16px;
    color: #666666;
      line-height: 24px;
}

</style>
